import React from "react";
import Bitmap from '../../Assets/images/Bitmap.png';
import bit from '../../Assets/images/bit.png';
import path from '../../Assets/images/path.png';
import map from '../../Assets/images/map.png';
import { Helmet } from 'react-helmet';
import ServiceForm from '../../Component/ServiceForm';

const PropertyManagement = () => {
  return (
    <React.Fragment>
        <Helmet>
            <title>Property Management</title>
            <meta name="description" content="See our Property Management offering." />
        </Helmet>
      <section className="banner_inde padding_tb ">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">SERVICES</h3>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Property Management</h2>
              
              <p className="hd-color">
              Investing in your future has never been easier with Vortexx. Our team of experienced and knowledgeable professionals is committed to providing tailored property management solutions that help you maximize returns, reduce stress, and save both time and money. Whether you're a seasoned investor or just starting out, we take the guesswork out of managing real estate. Effective property management is essential to protecting your investment and ensuring long-term success—and that's exactly what we deliver. Contact us today to learn more.   
              
              </p>
            </div>
            
          </div>
        </div>
      </section>
      
      <section className="tation " >
        <div className="container">
          <div className="grid_mtwo">
            <div className="inr_grid">
              <h3 className="hd-color MR-reg-60">
                Property Management
                <br />
                <span className="p-color M-Bold_60">Simplified</span>
              </h3>
              <p className=" hd-color">
                As an entrepreneur, you’re always looking for new ways to become more
                efficient and more cost-effective. That's why we focus on
                providing you with a wide range of dependable and cost-efficient
                services that will help you succeed. 
              </p>
              <p className=" hd-color">
                We’ve worked with businesses of all sizes, from local startups to established
                corporations, so we can help you find the right solution based around your requirements.
                
              </p>
              <ul className="main_ul">
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Maintenance and Repairs: Property managers are responsible for overseeing 
                  maintenance and repair work to ensure that properties are kept in good condition
                  and are safe for tenants to occupy. They can also negotiate contracts with contractors 
                  and suppliers to get the best prices and quality of work.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Rent Collection: Vortexx management handles rent collection and can take legal action against tenants who do not pay their rent. 
                  Our staff also maintains accurate records of rent and financial statements for the property owner.{' '}
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Marketing and Tenant Acquisition: Our staff will market the property and screen potential tenants to find the best fit for your property. 
                  We handle lease agreements, move-in and move-out processes, and other administrative tasks related to tenant management.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Compliance with Laws and Regulations: Vortexx assists in keeping your proeprty up-to-date with local, state, and federal laws and regulations related to your property.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Conflict Resolution: Our staff will manage your disputes and can mediate to resolve any conflicts that arise.
                </li>
              </ul>
            </div>
            <div className="inr_grid wid">
              <img src={map} />
            </div>
          </div>
        </div>
      </section>
      <ServiceForm />
    </React.Fragment>
    
  );
};

export default PropertyManagement;
